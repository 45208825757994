<template>
  <div class="blank_asset5">
    <div class="head_tbl">
      <div class="pos_left">
        <!-- <strong class="tit_text">인프라정산서 전표정보</strong> -->
        <strong class="tit_text">전표</strong>
      </div>
      <div v-if="docflag == ''" class="pos_right">
        <!-- <button type="button" class="btn_medium btn_secondary"><span class="ico_account ico_download_w"></span>템플릿 다운로드</button> -->
        <button type="button" class="btn_medium btn_secondary">
          <span class="ico_account ico_upload_w" />전표 정보 업로드
        </button>
      </div>
    </div>
    <div class="tbl_comm tbl_view">
      <table>
        <colgroup>
          <col style="width: 171px" />
          <col />
          <col style="width: 171px" />
          <col />
        </colgroup>
        <tbody>
          <tr>
            <!-- <th>기안번호</th>
            <td>{{ voucher.voucherCid }}</td> -->
            <th>지급처</th>
            <td colspan="3">
              {{ voucher.erpPartnerName }}
            </td>
          </tr>
          <tr>
            <th>전표일자</th>
            <td>{{ voucher.voucherDate }}</td>
            <th>증빙일자</th>
            <td>{{ voucher.proofDate }}</td>
          </tr>
          <!-- docflag == 'AEW' &&  -->
          <tr v-if="!isAddApprover && apprType === 'F'">
            <th>저장상태로 전표 생성</th>
            <td>{{ voucher.voucherSaveModeYn ? "Y" : "N" }}</td>
            <th>전표생성일</th>
            <td>{{ voucher.voucherDate }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="tbl_comm tbl_list tbl_list_sm8">
      <table>
        <colgroup>
          <col />
          <!-- No. -->
          <col style="width: 124px" />
          <!-- 계정과목 -->
          <col style="width: 124px" />
          <!-- 세목 -->
          <col style="width: 124px" />
          <!-- 부서 -->
          <col />
          <!-- 바코드 -->
          <col />
          <!-- 자산명 -->
          <col style="width: 124px" />
          <!-- 프로젝트코드 -->
          <col style="width: 124px" />
          <!-- 구분 -->
          <col />
          <!-- 수량 -->
          <col />
          <!-- 금액 -->
        </colgroup>
        <thead>
          <tr>
            <th>No.</th>
            <th>계정과목</th>
            <th>세목</th>
            <th>부서</th>
            <th>바코드</th>
            <th>자산명</th>
            <th>프로젝트코드</th>
            <th>구분</th>
            <th>수량</th>
            <th>금액</th>
          </tr>
        </thead>
        <tbody>
          <InfraVoucherListLine
            v-for="(item, index) in voucher.voucherItems"
            :key="index"
            :index="index"
            :rowData="item"
          />
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import InfraVoucherListLine from "./InfraVoucherListLine";

export default {
  name: "InfraVoucher",
  components: {
    InfraVoucherListLine,
  },
  props: {
    companyName: String,
    voucher: Object,
    docflag: String,
    apprType: String,
    isAddApprover: Boolean,
  },
  methods: {
    onFocusout(e) {
      e.target.classList.remove("error");
    },
  },
};
</script>
